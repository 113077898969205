export default {
  en: {
    currency: {
      style: 'currency',
      currency: 'USD'
    },
    percent: {
      style: 'percent'
    }
  },
  hu: {
    currency: {
      style: 'currency',
      currency: 'HUF'
    },
    percent: {
      style: 'percent'
    }
  }
}