









































































import { Component, Vue } from 'vue-property-decorator'

@Component
export default class App extends Vue {

  private currentTabItem = ''
  private drawer = false

  private menuItems = [
    {
    name: 'mainMenu.home',
    url: '/home'
    },
    {
    name: 'mainMenu.news',
    url: '/news'
    },
    {
    name: 'mainMenu.aboutMe',
    url: '/aboutMe'
    }, {
    name: 'mainMenu.works',
    url: '/works'
    }, {
    name: 'mainMenu.galleries',
    url: '/galleries'
    },  {
    name: 'mainMenu.contact',
    url: '/contacts'
    }
  ]
}
