

















































import { Component, Vue, Prop } from 'vue-property-decorator'
import { NZCarousel as NZCarouselType} from '@/types/page'

@Component
export default class NZCarousel extends Vue {
  @Prop({type: Object, required: true}) private item: NZCarouselType
  
  public overlay = false
  private currentItem = 0
  private srcSet = []

  private created (): void {
    this.item.carousel.forEach(c => this.srcSet.push(c.srcSet.split(',').map(f => {
      const i = f.split(/ +/)
      return require('@/assets' + this.item.folder + '/' + i[0]) + ' ' + i[1]
    }).join(',')))

    // console.log('srcset: ', this.srcSet)
  }

  private closeGallery (): void {
    this.overlay = false
  }
}
