













import { Component, Vue, Prop } from 'vue-property-decorator'
import { NZYouTube as NZYouTubeType } from '@/types/page'

@Component
export default class NZYouTube extends Vue {
  @Prop({type: Object, required: true}) private item: NZYouTubeType
}
