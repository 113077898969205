







import { Component, Vue, Prop } from 'vue-property-decorator'
import { NZText as NZTextType } from '@/types/page'

@Component
export default class NZText extends Vue {
  @Prop({type: Object, required: true}) private item: NZTextType
}
