import hu from 'vuetify/src/locale/hu'
import en from 'vuetify/src/locale/en'

export default {
  en: {
    ...en,
    mainMenu: {
      home: 'Home',
      news: 'News',
      aboutMe: 'About me',
      design: 'Design',
      sculptures: 'Sculptures',
      projects: 'Projects',
      links: 'Links',
      articles: 'Articles',
      contact: 'Contact',
      galleries: 'Galleries',
      works: 'Works'
    }
  },
  hu: {
    ...hu,
    mainMenu: {
      home: 'Kezdőlap',
      news: 'Aktuális',
      aboutMe: 'Magamról',
      design: 'Design',
      scultures: 'Szobrok',
      projects: 'Projekt',
      links: 'Linkek',
      articles: 'Cikkek',
      galleries: 'Galériák',
      contact: 'Kapcsolat',
      works: 'Munkák'
    }
  }
}