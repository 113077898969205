import Vue from 'vue'
import Vuex, { MutationTree, GetterTree, ActionTree } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const plugins = [
  createPersistedState({ storage: window.sessionStorage })
]

const state = {
  view: 'home'
}
type State = typeof state

const getters: GetterTree<State, any> = {
  getView: (state: State) => state.view
}

const actions: ActionTree<State, any> = {
  setView({ commit }, view: string) {
    commit('setView', view)
  }
}

const mutations: MutationTree<State> = {
  setView(state: State, view) {
    state.view = view
  }
}

export default new Vuex.Store({
  plugins, 
  state,
  getters,
  mutations,
  actions,
  modules: {}
})
