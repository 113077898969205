




















import { Component, Vue, Prop } from 'vue-property-decorator'
import { NZList as NZListType } from '@/types/page'

@Component
export default class NZList extends Vue {
  @Prop({type: Object, required: true}) private item: NZListType

}
