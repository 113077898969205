
















































































































































import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Admin extends Vue {

  private size = ['body-1']
  private fontWeight = ['regular']
  private decoration = ['none']
  private italic = ['none']
  private opacity = ['primary']
  private color = ['grey']
  // private colorVariant = ['lighten-5']
  private colorText = ['grey']
  private colorTextVariant = ['darken-4']
}
