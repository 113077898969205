













import { Component, Vue, Prop } from 'vue-property-decorator'
import { NZExpansionPanel } from '@/types/page'

@Component({
  name: 'NZExpansion',
})
export default class NZExpansion extends Vue {
  @Prop({type: Array, required: true}) private item: NZExpansionPanel[]
}
