
















import { Component, Vue, Prop } from 'vue-property-decorator'
import { NZPdf as NZPdfType } from '@/types/page'

@Component
export default class NZPdf extends Vue {
  @Prop({type: Object, required: true}) private item: NZPdfType

  private pdfFile = null

  private created (): void {
    if (this.item.url.startsWith('http')) {
      this.pdfFile = this.item.url
    } else {
      this.pdfFile = require('@/assets' + this.item.folder + '/' + this.item.url)
    }
  }

  private openTab (): void {
    window.open(this.pdfFile, '_blank')
  }
}
