import { render, staticRenderFns } from "./NZText.vue?vue&type=template&id=48bcd4eb&scoped=true&"
import script from "./NZText.vue?vue&type=script&lang=ts&"
export * from "./NZText.vue?vue&type=script&lang=ts&"
import style0 from "./NZText.vue?vue&type=style&index=0&id=48bcd4eb&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48bcd4eb",
  null
  
)

export default component.exports