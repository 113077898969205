import '@/router/class-component-hooks'
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueI18n from 'vue-i18n'
import messages from '@/lang/messages'
import dateTimeFormats from '@/lang/datetime'
import numberFormats from '@/lang/numbers'
import NZPage from '@/components/NZPage.vue'

Vue.use(VueI18n)

Vue.config.productionTip = false
const i18n = new VueI18n({
  // locale: navigator.language,
  locale: 'en',
  fallbackLocale: 'en',
  silentTranslationWarn: true,
  messages,
  dateTimeFormats,
  numberFormats
})

Vue.component('nz-page', NZPage)

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
