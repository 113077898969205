











import { Component, Vue, Prop } from 'vue-property-decorator'
import NZCarousel from '@/components/widgets/NZCarousel.vue'
import NZImage from '@/components/widgets/NZImage.vue'
import NZText from '@/components/widgets/NZText.vue'
import NZList from '@/components/widgets/NZList.vue'
import NZLinkImg from '@/components/widgets/NZLinkImg.vue'
import NZPdf from '@/components/widgets/NZPdf.vue'
import NZYouTube from '@/components/widgets/NZYouTube.vue'
import NZContacts from '@/components/widgets/NZContacts.vue'
import NZExpansion from '@/components/widgets/NZExpansion.vue'
import { Page } from '@/types/page'

@Component({
  name: 'NZPage',
  components: {
    NZCarousel,
    NZImage,
    NZText,
    NZList,
    NZLinkImg,
    NZExpansion,
    NZPdf,
    NZYouTube,
    NZContacts
  }
})
export default class NZPage extends Vue {
  @Prop({type: Array, required: true}) public page: Page

  private mounted (): void {
    console.log('page: ', this.page)
  }
}
