















import { Component, Vue, Prop } from 'vue-property-decorator'
import { NZImage as NZImageType } from '@/types/page'

@Component
export default class NZImage extends Vue {
  @Prop({type: Object, required: true}) private item: NZImageType

  private srcSet = null

  private created (): void {
    this.srcSet = this.item.srcSet.split(',').map(f => {
      const i = f.split(/ +/)
      return require('@/assets' + this.item.folder + '/' + i[0]) + ' ' + i[1]
    }).join(',')

    // console.log('srcset: ', this.srcSet)

  }
}
