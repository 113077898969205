















import { Component, Vue, Prop } from 'vue-property-decorator'
import { NZLinkImg as NZLinkImgType } from '@/types/page'

@Component
export default class NZLinkImg extends Vue {
  @Prop({type: Object, required: true}) private item: NZLinkImgType

private srcSet = null

  private created (): void {
    if (this.item.srcSet) {
      this.srcSet = this.item.srcSet.split(',').map(f => {
        const i = f.split(/ +/)
        return require('@/assets' + this.item.folder + '/' + i[0]) + ' ' + i[1]
      }).join(',')
    }
  }
}
