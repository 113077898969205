





































































import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'NZContacts',
  components: {

  }
})
export default class NZContacts extends Vue {

}
