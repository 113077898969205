import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Admin from '@/views/admin/Admin.vue'
import NZPage from '@/components/NZPage.vue'
import Contacts from '@/components/Contacts.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/home',
    name: 'Page',
    component: NZPage,
    props: () => ({ page: require('@/assets/bp/home/home.json') })
  },
  {
    path: '/news',
    name: 'News',
    component: NZPage,
    props: () => ({ page: require('@/assets/bp/news/news.json') })
  },
  {
    path: '/aboutMe',
    name: 'AboutMe',
    component: NZPage,
    props: () => ({ page: require('@/assets/bp/aboutMe/aboutMe.json') })
  },
  {
    path: '/works',
    name: 'Works',
    component: NZPage,
    props: () => ({ page: require('@/assets/bp/works/works.json') })
  },
  {
    path: '/galleries',
    name: 'Galleries',
    component: NZPage,
    props: () => ({ page: require('@/assets/bp/galleries/galleries.json') })
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: NZPage,
    props: () => ({ page: require('@/assets/bp/contacts/contacts.json') })
  },
  {
    path: '/admin',
    name: 'Admin ',
    component: Admin
  },
  {
    path: '*',
    redirect: '/home'
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
